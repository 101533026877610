import React, { useState, useEffect } from "react";
import { images } from "../../constants";
import { AppWrap, MotionWrap } from "../../wrapper";
import { client } from "../../client";
import AnimateLetters from "../AnimateLetters/AnimateLetters";

import "./Footer.scss";

const Footer = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  const [loading, setLoading] = useState(false);

  const { name, email, message } = formData;

  const handleChangeInput = (e) => {
    const { name, value } = e.target;

    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = () => {
    setLoading(true);

    const contact = {
      _type: "contact",
      name: name,
      email: email,
      message: message,
    };

    client.create(contact).then(() => {
      setLoading(false);
      setIsFormSubmitted(true);
    });
  };

  // Word Animation

  const [letterClass, setLetterClass] = useState("text-animate");
  useEffect(() => {
    let timeoutId = setTimeout(() => {
      setLetterClass("text-animate-hover");
    }, 3000);

    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  const arr1 = ["T", "a", "k", "e", " ", "A"];
  const arr2 = ["W", "i", "t", "h", " ", "M", "e"];

  // Word animation end

  return (
    <>
      <h2 className="headline">
        <AnimateLetters letterClass={letterClass} strArray={arr1} idx={11} />{" "}
        <p className={`${letterClass}`}>Coffee</p>{" "}
        <p className={`${letterClass}`}>&</p> <br />
        <p className={`${letterClass}`}>Chat</p>{" "}
        <AnimateLetters letterClass={letterClass} strArray={arr2} idx={7} />
      </h2>
      <div className="app__footer-cards">
        <div className="app__footer-card">
          <img src={images.email} alt="email" />
          <a href="mailto:ritankardebanth2001@gmail.com" className="p-text">
            ritankardebanth2001@gmail.com
          </a>
        </div>
        <div className="app__footer-card">
          <img src={images.mobile} alt="mobile" />
          <a href="tel: +91 (8240) 361-634" className="p-text">
            (8240) 361-634
          </a>
        </div>
      </div>

      {!isFormSubmitted ? (
        <div className="app__footer-form app__flex">
          <div className="app__flex">
            <input
              type="text"
              placeholder="Your Name"
              className="p-text"
              name="name"
              value={name}
              onChange={handleChangeInput}
            />
          </div>
          <div className="app__flex">
            <input
              type="email"
              placeholder="Your Email"
              className="p-text"
              name="email"
              value={email}
              onChange={handleChangeInput}
            />
          </div>
          <div>
            <textarea
              name="message"
              placeHolder="your Message"
              value={message}
              onChange={handleChangeInput}
              className="p-text"
            />
          </div>
          <button type="button" className="p-text" onClick={handleSubmit}>
            {loading ? "Sending" : "Send Message"}
          </button>
        </div>
      ) : (
        <div>
          <h3 className="head-text">Thank you for getting in touch</h3>
        </div>
      )}
    </>
  );
};

export default AppWrap(
  MotionWrap(Footer, "app__footer"),
  "contact",
  "app__primarybg"
);
