import React from "react";
import "./App.scss";

// React Router Dom

import { Routes, Route } from "react-router-dom";

// imports from Container
import { About, Footer, Header, Skills, Work, Layout } from "./container";
// imports from Components
// import { Navbar } from "./components";

const App = () => {
  return (
    <div className="app">
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Header />} />
          <Route path="/home" element={<Header />} />
          <Route path="/about" element={<About />} />
          <Route path="/work" element={<Work />} />
          <Route path="/skills" element={<Skills />} />
          <Route path="/contact" element={<Footer />} />
        </Route>
      </Routes>
    </div>
  );
};

export default App;
