import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";

import { AppWrap, MotionWrap } from "../../wrapper";
import "./About.scss";
import AnimateLetters from "../AnimateLetters/AnimateLetters";
import { urlFor, client } from "../../client";

const About = () => {
  const [abouts, setAbouts] = useState([]);

  // Word Animation

  const [letterClass, setLetterClass] = useState("text-animate");
  useEffect(() => {
    let timeoutId = setTimeout(() => {
      setLetterClass("text-animate-hover");
    }, 4000);

    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  const arr1 = [
    "P",
    "r",
    "o",
    "b",
    "l",
    "e",
    "m",
    " ",
    "S",
    "o",
    "l",
    "v",
    "e",
    "r",
  ];
  const arr2 = ["S", "e", "e", "k", "i", "n", "g", " ", "n", "e", "w"];

  // Word animation end

  useEffect(() => {
    const query = '*[_type == "abouts"]';

    client.fetch(query).then((data) => {
      setAbouts(data);
    });
  }, []);

  return (
    <>
      <h2 className="headline">
        <p className={`${letterClass}`}>Passionate</p>{" "}
        <AnimateLetters letterClass={letterClass} strArray={arr1} idx={11} />{" "}
        <br />
        <AnimateLetters
          letterClass={letterClass}
          strArray={arr2}
          idx={23}
        />{" "}
        <p className={`${letterClass}`}>Challenges</p>
      </h2>

      <div className="app__profiles">
        {abouts.map((about, index) => (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            whileHover={{ scale: 1.1 }}
            transition={{ duration: 0.5, type: "spring" }}
            className="app__profile-item"
            key={about.title + index}
          >
            <img src={urlFor(about.imgUrl)} alt={about.title} />
            <h2 className="bold-text about-title" style={{ marginTop: 20 }}>
              {about.title}
            </h2>
            <p className="p-text about-desc" style={{ marginTop: 10 }}>
              {about.description}
            </p>
          </motion.div>
        ))}
      </div>
    </>
  );
};
export default AppWrap(
  MotionWrap(About, "app__about"),
  "about",
  "app__whitebg"
);
