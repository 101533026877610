import React from "react";

import { FaGithubAlt, FaLinkedinIn } from "react-icons/fa";

import { SiLeetcode } from "react-icons/si";

const SocialMedia = () => (
  <div className="app__social">
    <div>
      <a
        href="https://www.linkedin.com/in/ritankar-debnath-1136441a5"
        target="_blank"
        rel="noreferrer"
      >
        <FaLinkedinIn />
      </a>
    </div>
    <div>
      <a href="https://leetcode.com/kiz9r" target="_blank" rel="noreferrer">
        <SiLeetcode />
      </a>
    </div>
    <div>
      <a href="https://github.com/Kiz9R" target="_blank" rel="noreferrer">
        <FaGithubAlt />
      </a>
    </div>
  </div>
);

export default SocialMedia;
