import { React, useState, useEffect } from "react";
import "./Header.scss";

import AnimateLetters from "../AnimateLetters/AnimateLetters";
import { motion } from "framer-motion";
import { images } from "../../constants";
import { AppWrap } from "../../wrapper";

const scaleVariants = {
  whileInView: {
    scale: [0, 1],
    opacity: [0, 1],
    transition: {
      duration: 1,
      ease: "easeInOut",
    },
  },
};

const Header = () => {
  // Word Animation
  const [letterClass, setLetterClass] = useState("text-animate");

  const nameArray = ["R", "i", "t", "a", "n", "k", "a", "R"];
  const arr1 = ["R", "E", "S", "U", "M", "E"];

  useEffect(() => {
    let timeoutId = setTimeout(() => {
      setLetterClass("text-animate-hover");
    }, 4000);

    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  // Word Animation end

  return (
    <div className="app__header app__flex">
      <motion.div
        whileInView={{ x: [-100, 0], opacity: [0, 1] }}
        transition={{ duration: 0.5 }}
        className="app__header-info"
      >
        <div className="app__header-badge">
          <div className="badge-cmp app__flex">
            <span>😎</span>
            <div style={{ marginLeft: 20 }}>
              <h1 className="name-text">
                <span className={`${letterClass}`}>Hi,</span>{" "}
                <span className={`${letterClass} _13`}>I</span>
                <span className={`${letterClass} _14`}>'m</span>
                <br />
                <AnimateLetters
                  letterClass={letterClass}
                  strArray={nameArray}
                  idx={15}
                />
              </h1>
            </div>
          </div>
          <div className="tag-cmp app__flex">
            <p className={`${letterClass} _24`}>Student</p>
            <p className={`${letterClass} _25`}>Web Developer</p>
          </div>

          <a
            href="https://drive.google.com/file/d/1AqqzbuniCpDWcGXPdDrT11YJL8Mpe25R/view?usp=sharing"
            target="_blank"
            rel="noreferrer"
          >
            <div className="tag-cmp app__flex">
              <h3>
                <AnimateLetters
                  letterClass={letterClass}
                  strArray={arr1}
                  idx={26}
                />
              </h3>
            </div>
          </a>
        </div>
      </motion.div>
      <motion.div
        whileInView={{ opacity: [0, 1] }}
        transition={{ duration: 0.5, delayChildren: 0.5 }}
        className="app__header-img"
      >
        <img src={images.profile} alt="profile-bg" />
        <motion.img
          whileInView={{ scale: [0, 1] }}
          transition={{ duration: 1, ease: "easeInOut" }}
          src={images.circle}
          alt="profile-circle"
          className="overlay_circle"
        />
      </motion.div>
      <motion.div
        variant={scaleVariants}
        whileInView={scaleVariants.whileInView}
        className="app__header-circles"
      >
        {[images.java, images.node, images.react].map((circle, index) => (
          <div className="circle-cms app__flex" key={`circle-${index}`}>
            <img src={circle} alt="circle" />
          </div>
        ))}
      </motion.div>
    </div>
  );
};

export default AppWrap(Header, "home");
