import { React, useState, useEffect } from "react";
import "./Navbar.scss";

import { NavLink } from "react-router-dom";

import { HiMenuAlt4, HiX } from "react-icons/hi";
import { motion } from "framer-motion";
import { images } from "../../constants";

// import { AnimateLetters } from "../../container/AnimateLetters/AnimateLetters";

import { FaGithubAlt, FaLinkedinIn } from "react-icons/fa";
import { SiLeetcode } from "react-icons/si";

const Navbar = () => {
  const [toggle, setToggle] = useState(false);

  // Word Animation
  const [letterClass, setLetterClass] = useState("text-animate");

  useEffect(() => {
    let timeoutId = setTimeout(() => {
      setLetterClass("text-animate-hover");
    }, 1000);

    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  // Word Animation end

  return (
    <nav className="app__navbar">
      <div className="app__navbar-logo">
        <img src={images.logo} alt="logo" />
      </div>
      <ul className="app__navbar-links">
        {["home", "about", "work", "skills", "contact"].map((item) => (
          <li className="app__flex p-text" key={`Link-${item}`}>
            <div />
            <NavLink
              exact="true"
              className="app__navbar-navlink"
              to={`/${item}`}
            >
              <span className={`${letterClass}`}>{item}</span>
            </NavLink>
          </li>
        ))}
      </ul>
      <div className="app__navbar-menu">
        <HiMenuAlt4 onClick={() => setToggle(true)} />
        {toggle && (
          <motion.div
            whileInView={{ x: [300, 0] }}
            transition={{ duration: 0.85, ease: "easeOut" }}
          >
            <HiX onClick={() => setToggle(false)} />
            <ul>
              {["home", "about", "work", "skills", "contact"].map((item) => (
                <li key={item}>
                  <NavLink
                    exact="true"
                    className="app__navbar-navlink"
                    to={`/${item}`}
                    onClick={() => setToggle(false)}
                  >
                    <span className={`${letterClass}`}>{item}</span>
                  </NavLink>
                </li>
              ))}
            </ul>

            {/* social media */}
            {/* <div className="example"> */}
            <ul className="social-links">
              <li>
                <a
                  href="https://www.linkedin.com/in/ritankar-debnath-1136441a5"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaLinkedinIn />
                </a>
              </li>
              <li>
                <a
                  href="https://leetcode.com/kiz9r"
                  target="_blank"
                  rel="noreferrer"
                >
                  <SiLeetcode />
                </a>
              </li>
              <li>
                <a
                  href="https://github.com/Kiz9R"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaGithubAlt />
                </a>
              </li>
            </ul>
            {/* </div> */}

            {/* end of social */}
          </motion.div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
